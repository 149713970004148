.search-box {
  &_icon {
    position: relative;
    right: 41px;
    border-left: 1px solid $gray-light;
    padding: 8px;
  }

  &_select {
    &.ant-select-show-search {
      &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-radius: 10px;
      }
    }
  }
}
