.inviteCodesPage {
  td.ant-table-column-sort {
    background-color: transparent;
  }

  .invitecode {
    word-wrap: break-word;
    word-break: break-all;
  }
}
