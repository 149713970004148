.custom-simple-pagination {
  .ant-pagination-simple {
    .ant-pagination-next,
    .ant-pagination-prev {
      height: 32px;
      width: 32px;
      border: 1px solid $gray-light;

      .ant-pagination-item-link {
        height: 32px;
        width: 32px;
      }
    }

    .ant-pagination-simple-pager input {
      margin: 0;
      padding: 0;
      height: 32px;
    }
  }

  .ant-select.ant-select-single.ant-select-show-arrow {
    margin-left: 30px;
  }
}
