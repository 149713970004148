.applicationsPage {
  &-card {
    > div {
      animation-name: flash;
      animation-duration: 0.5s;
    }
  }

  &-details {
    flex: auto;
    width: 100%;

    @include starts-at('md') {
      flex: 1 1 0;
    }
  }
}

@keyframes flash {
  0%,
  100% {
    box-shadow: 2px 2px 3px #7e7c7cb0;
  }
  50% {
    box-shadow: 1px 1px 10px #214e7a;
  }
}
