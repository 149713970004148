.settingsEdit {
  &-cta {
    margin-top: 20px;
  }

  &-form {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &-jsonEditor {
    width: 100%;

    &.-fullscreen {
      width: 100vw;
      height: 100vh;
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      background: white;

      > div {
        height: 100%;
      }
    }
  }

  .jse-menu .custom-fullscreen-button.jse-button.svelte-497ud4 {
    margin-left: auto;
  }
}
