.authentication-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  background-color: $gray-69op;

  & > .authentication-modal {
    border-radius: 10px;
    min-width: 300px;
    border: 1px solid $blue-dark;
    overflow: hidden;
    background-color: $white;

    & > div > .ant-typography {
      color: $white;
      background-color: $blue-dark;
      text-align: center;
      padding: 16px;
    }

    & > div > .ant-space > .ant-space-item > .ant-typography {
      font-size: 20px;
      font-style: italic;
      padding: 10px;
    }
  }
}
