.ant-layout {
  &-content {
    &.content {
      margin: 64px 16px 0;
      overflow: initial;
    }
  }

  &-header {
    padding-left: 25px;

    &.header {
      position: fixed;
      left: 0;
      z-index: 3;
      width: calc(100%);
      display: inline-flex;
      padding: 0 20px 0 0;
  
      .ant-select-arrow-loading {
        color: $white;
        left: calc(50% - 8px);
        font-size: 15px;
      }

      .ant-select-selection-item {
        color: $white;
      }
    }
  }
}
