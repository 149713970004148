/* General */
.site-layout-background {
  box-shadow: 0 1px 3px $gray-69op;
  border-radius: 5px;
  background: $white;
  overflow: auto;
  margin: 0;
}

/* Header */
.ant-layout-header.header {
  height: auto;
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;
  padding: 0;

  @include starts-at('xs') {
    flex-direction: row;
    height: 64px;
  }

  .header-right {
    padding-right: 10px;
  }

  .header-left {
    .platform-select {
      min-width: 190px;
      border: 1px solid $white;
      color: $white;
      border-radius: 5px;
      .ant-select-selector {
        background-color: transparent;
      }
    }
    .ant-select-arrow .anticon {
      & > svg {
        color: $white;
      }
    }
  }
}

/* Logo */
.logo {
  img {
    width: 50px;
  }
}

/* Aside */
.sider {
  z-index: 2;
  margin-top: 64px;
}

.anticon-menu.drawer-button {
  font-size: 18px;
  min-width: 50px; 
  padding: 0 0 0 8px;
  color: $white;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.drawer-menu {
  .ant-drawer-close {
    position: absolute;
    right: 0;
  }

  .ant-drawer-body {
    padding: 0;
    overflow: hidden;
  }
}

.-toolTipMd {
  display: none;
}
