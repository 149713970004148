.avatarMenu {
  cursor: pointer;

  &_email {
    color: $color-primary;
    display: block;
    font-size: 12px;
    cursor: default;
  }

  &_infos {
    display: flex;
    flex-direction: column;
  }

  &_item {
    width: 100%;
    display: inline-block;

    &.-hide {
      display: none;
    }
  }

  &_name {
    cursor: default;
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &_noHover {
    &:hover {
      background-color: initial;
      cursor: default;
    }
  }

  &_separator {
    margin: 10px 0 0;
    width: 100%;
  }

  &_wrapper {
    max-width: 300px;

    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
    }
  }
}
