.inviteCodeEditPage {
  .assigmentsList {
    .ant-row .ant-col .ant-form-item-control-input {
      min-height: 0;
    }
    .ant-form-item-explain-error {
      font-weight: bold;
    }
  }
}
