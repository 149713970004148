/* root */
:root {
  --color-default: #001529;
  --color-primary: #001529; // TBD
  --color-primary-dark: #010b14; // TBD
  --color-primary-light: #1678d3; // TBD
  --web-height: 100vh;
}

/* Colors */
$color-primary: var(--color-primary);
$color-primary-dark: var(--color-primary-dark);

$black: #000000;

$blue-dark: #001529;

$red: #ff0000;
$red-light: #ff4d4f;

$white: #ffffff;

$gray: #cccccc;
$gray-light: #d9d9d9;
$gray-69op: rgb(203 203 203 / 69%);

/* Fonts */
