.field-disabled .ant-input.ant-input-disabled,
.show-only-field.ant-input-disabled.ant-input,
.show-only-field.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.show-only-field.ant-select-disabled.ant-select:not(.ant-select-customize-input).ant-select-multiple
  .ant-select-selector {
  background-color: $white;
  color: $black;
  border: none;
  cursor: text;
  padding-left: 0;
}

.show-only-field.ant-select-disabled.ant-select:not(.ant-select-customize-input).ant-select-multiple
  .ant-select-selector
  .ant-select-selection-overflow-item,
.show-only-field.ant-select-disabled.ant-select:not(.ant-select-customize-input).ant-select-multiple
  .ant-select-selector
  .ant-select-selection-item {
  background: $white;
  color: $black;
  cursor: text;
}

.ant-select-disabled.ant-select-single.show-only-field .ant-select-arrow {
  display: none;
}
