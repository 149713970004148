.full-width {
  width: 100%;
}

.overflowHidden {
  overflow: hidden;
}

.requiredField {
  &:before {
    display: inline-block;
    margin-right: 4px;
    color: $red-light;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.txt {
  &-bold {
    font-weight: bold;
  }

  &-normal {
    font-weight: normal;
  }

  &-white {
    color: $white;
  }
}

.uppercase {
  text-transform: uppercase;
}
