.application-select {
  direction: ltr;
  min-width: 190px;
  border: 1px solid $white;
  color: $white;
  border-radius: 5px;

  .ant-select-arrow .anticon {
    & > svg {
      color: $white;
    }
  }
}
