// Ant Design Breakpoints
// https://ant.design/components/layout#breakpoint-width

$breakpoints: (
  'xs': (
    min-width: 480px,
  ),
  'sm': (
    min-width: 576px,
  ),
  'md': (
    min-width: 768px,
  ),
  'lg': (
    min-width: 992px,
  ),
  'xl': (
    min-width: 1200px,
  ),
  'xxl': (
    min-width: 1600px,
  ),
) !default;

@mixin starts-at($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // Error message
  @else {
    @warn "No results from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
